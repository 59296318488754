<template lang="pug">
div
  page-header(:title='title', :url='url', :titleNew='titleNew')
  grid(:url='urlDataSource', :columns='columns')
</template>

<script>
import PageHeader from '@/components/PageHeader'
import Grid from '@/components/Grid'

import GridTemplate from '@/mixins/GridTemplate'
import Permission from '@/mixins/Permission'

export default {
  mixins: [GridTemplate, Permission],
  components: {
    PageHeader,
    Grid
  },
  data() {
    return {
      title: 'Grupo de Acesso',
      titleNew: '',
      url: '/admin/grupo-de-acesso/',
      urlDataSource: `${this.$url}/perfil`,
      columns: [
        {
          field: 'titulo',
          title: 'Título',
          width: 400
        }
      ]
    }
  },
  methods: {
    verifPermissao() {
      this.titleNew = this.ACTION.c ? 'Novo' : ''
      if (this.ACTION.e) {
        this.columns.push({
          field: 'ativo',
          title: 'Ações',
          width: 105,
          template: this.commandTemplate
        })
      }
    }
  },
  created() {
    this.verifPermissao()
  }
}
</script>

<style lang="stylus" scoped></style>
