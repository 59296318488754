<template lang="pug">
div
  page-header(:title='title')
  .error-container
    .well
      h1.grey.lighter.smaller
        span.blue.bigger-125
          i.ace-icon.fa.fa-sitemap
          |  404
        |  Página não encontrada
      hr
      h3.lighter.smaller procuramos em todos lugares mas não encontramos esta página!
      div
        .space
        h4.smaller Tente um dos seguintes procedimentos:
        ul.list-unstyled.spaced.inline.bigger-110.margin-15
          li
            i.ace-icon.fa.fa-hand-o-right.blue
            | Verifique novamente o URL para erros de digitação
          li
            i.ace-icon.fa.fa-hand-o-right.blue
            | Conte-nos sobre isso
      hr
      .space
      .center
        a.btn.btn-grey(href='javascript:history.back()')
          i.ace-icon.fa.fa-arrow-left
          | Voltar
        router-link.btn.btn-primary(to='/')
          i.ace-icon.fa.fa-home
          | Home

</template>

<script>
import PageHeader from '@/components/PageHeader'

export default {
  components: {
    PageHeader
  },
  data() {
    return {
      title: 'Erro 404'
    }
  }
}
</script>

<style lang="stylus" scoped></style>
