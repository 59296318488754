import swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

export default {
  error(text, timer = null) {
    swal.fire({
      title: 'Erro!',
      text,
      icon: 'error',
      timer
    })
  },
  success(text, timer = null) {
    swal.fire({
      title: 'Sucesso!',
      text,
      icon: 'success',
      timer
    })
  },
  dialog(title, text, confirmButtonText = 'Sim') {
    return new Promise((resolve, reject) => {
      swal
        .fire({
          title,
          text,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText,
          cancelButtonText: 'Cancelar'
        })
        .then(result => {
          return resolve(result)
        })
        .catch(e => reject(e))
    })
  }
}
