<template lang="pug">
div
  .input-group.color-picker(ref='colorpicker')
    input.form-control(type='text', v-model='colorValue', @focus='showPicker()', @input='updateFromInput')
    span.input-group-addon.color-picker-container
      span.current-color(:style="{ backgroundColor: color }", @click='togglePicker()')
      chrome-picker(:value='colors', @input='updateFromPicker', v-if='displayPicker')

</template>

<script>
import { Chrome } from 'vue-color'
export default {
  components: {
    'chrome-picker': Chrome
  },
  props: ['color'],
  data() {
    return {
      colors: {
        hex: '#000000'
      },
      colorValue: '',
      displayPicker: false
    }
  },
  mounted() {
    this.colorValue = this.color
    this.setColor(this.color || '#000000')
  },
  methods: {
    setColor(color) {
      this.updateColors(color)
      this.colorValue = color
    },
    updateColors(color) {
      if (color.slice(0, 1) === '#') {
        this.colors = {
          hex: color
        }
      } else if (color.slice(0, 4) === 'rgba') {
        const rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(',')
        const hex =
          '#' +
          (
            (1 << 24) +
            (parseInt(rgba[0]) << 16) +
            (parseInt(rgba[1]) << 8) +
            parseInt(rgba[2])
          )
            .toString(16)
            .slice(1)
        this.colors = {
          hex: hex,
          a: rgba[3]
        }
      }
    },
    showPicker() {
      document.addEventListener('click', this.documentClick)
      this.displayPicker = true
    },
    hidePicker() {
      document.removeEventListener('click', this.documentClick)
      this.displayPicker = false
    },
    togglePicker() {
      this.displayPicker ? this.hidePicker() : this.showPicker()
    },
    updateFromInput() {
      this.updateColors(this.colorValue)
    },
    updateFromPicker(color) {
      this.colors = color
      if (color.rgba.a === 1) {
        this.colorValue = color.hex
      } else {
        this.colorValue =
          'rgba(' +
          color.rgba.r +
          ', ' +
          color.rgba.g +
          ', ' +
          color.rgba.b +
          ', ' +
          color.rgba.a +
          ')'
      }
    },
    documentClick(e) {
      const el = this.$refs.colorpicker
      const target = e.target
      if (el !== target && !el.contains(target)) {
        this.hidePicker()
      }
    }
  },
  watch: {
    colorValue(val) {
      if (val) {
        this.updateColors(val)
        this.$emit('input', val)
        // document.body.style.background = val;
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.current-color
	display inline-block
	width 16px
	height 16px
	background-color #000
	cursor pointer

.color-picker-container {
    position absolute
    z-index 1
    margin-top 0
    right -25px
    height 34px
    width 33px
    }
</style>
