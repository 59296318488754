<template>
  <div>
    <page-header :title="title"></page-header>
    <form @submit.prevent="gravar">
      <div class="modal-body">
        <div class="form-horizontal">
          <vue-element-loading
            :active="isActive"
            spinner="bar-fade-scale"
            color="#428BCA"
          ></vue-element-loading>
          <input-form-text
            textInput="Nome"
            :required="true"
            v-model="form.nome"
            :value="form.nome"
            disabled
          ></input-form-text>
          <div class="form-group">
            <select-form
              v-model="form.planos_id"
              :value="form.planos_id"
              name="Planos"
              url="/planos"
              id="id"
              titulo="title"
              layout="true"
              optionText="Selecione"
              required="true"
              :disabled="true"
            ></select-form>
          </div>
          <input-form-text
            textInput="Telefone 1"
            :required="true"
            v-model="form.telefone1"
            :value="form.telefone1"
            disabled
          ></input-form-text>
          <input-form-text
            textInput="Telefone 2"
            :required="true"
            v-model="form.telefone2"
            :value="form.telefone2"
            disabled
          ></input-form-text>
          <input-form-text
            textInput="Whatsapp"
            :required="true"
            v-model="form.whatsapp"
            :value="form.whatsapp"
            disabled
          ></input-form-text>
          <input-form-text
            textInput="E-mail"
            :required="true"
            v-model="form.email"
            :value="form.email"
            disabled
          ></input-form-text>
          <input-form-text
            textInput="Endereço"
            :required="true"
            v-model="form.endereco"
            :value="form.endereco"
            disabled
          ></input-form-text>
        </div>
      </div>
      <footer-button disabled></footer-button>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VueElementLoading from 'vue-element-loading'
import PageHeader from '@/components/PageHeader'
import InputFormText from '@/components/form/InputForm'
import SelectForm from '@/components/form/SelectForm'
import FooterButton from '@/components/form/FooterButton'
import Permission from '@/mixins/Permission'
import Services from '@/utils/services'
import { erro } from '@/components/Message'

export default {
  components: {
    PageHeader,
    InputFormText,
    FooterButton,
    VueElementLoading,
    SelectForm
  },
  mixins: [Permission],
  computed: {
    ...mapGetters(['user'])
  },
  data() {
    return {
      title: 'Assine',
      form: {
        nome: '',
        telefone1: '',
        telefone2: '',
        whatsapp: '',
        endereco: '',
        email: '',
        ver: '',
        planos_id: ''
      },
      isActive: false
    }
  },
  methods: {
    ...mapActions(['getLogsTotal']),
    async gravar() {
      const body = this.form
      body.ver = 1
      if (this.$route.params.id) {
        await Services.put(`/assine/${this.$route.params.id}`, body).catch(
          response => {
            response
            erro()
          }
        )
      }
    },
    async getDados() {
      const dados = await Services.get(
        `/assine/${this.$route.params.id}`
      ).catch(response => {
        response
        return (this.isActive = false)
      })
      this.form.nome = dados.nome
      this.form.telefone1 = dados.telefone1
      this.form.telefone2 = dados.telefone2
      this.form.whatsapp = dados.whatsapp
      this.form.endereco = dados.endereco
      this.form.email = dados.email
      this.form.ver = dados.ver
      this.form.planos_id = dados.planos_id

      this.isActive = false
      this.gravar()
    },
    verifPermissao() {
      if (this.$route.params.id) {
        if (!this.ACTION.e) {
          this.$router.push('/erro')
        }
      } else {
        if (!this.ACTION.c) {
          this.$router.push('/erro')
        }
      }
    }
  },
  mounted() {
    this.verifPermissao()
    if (this.$route.params.id) {
      this.isActive = true
      this.getDados()
    }
  }
}
</script>

<style lang="stylus" scoped></style>
