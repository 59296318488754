<template lang="pug">
div
  page-header(:title='title', :url='url', :titleNew='titleNew')
  grid(:url='urlDataSource', :columns='columns', :grid='{height:500}', :source='source')
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import moment from 'moment'
import PageHeader from '@/components/PageHeader'
import Grid from '@/components/Grid'
moment.locale('pt-BR')

export default {
  components: {
    PageHeader,
    Grid
  },
  computed: {
    ...mapGetters(['user'])
  },
  data() {
    return {
      title: 'Chamado',
      titleNew: '',
      url: '/inicio/chamados/',
      urlDataSource: `${this.$url}/chamado`,
      columns: [
        {
          field: 'texto',
          title: 'Título',
          template: this.template,
          width: 400
        },
        {
          field: 'status',
          title: 'Ativo',
          width: 120
        },
        {
          field: 'created_at',
          title: 'Data Cad',
          width: 120
        },
        {
          field: 'updated_at',
          title: 'Data Atual',
          width: 120
        }
      ],
      source: {
        schemaParse: this.parse
      }
    }
  },
  methods: {
    parse(d) {
      d.map(i => {
        i.created_at = moment(i.created_at).format('L LT')
        i.updated_at = moment(i.updated_at).format('L LT')
        return i
      })
      return d
    },
    template(e) {
      return {
        template: Vue.component('temp', {
          template: `<div :style='templateArgs.status_id == 3 ? "text-decoration: line-through" : null'>
                {{ templateArgs.texto }}
             </div>`,
          data() {
            return {
              templateArgs: {}
            }
          }
        }),
        templateArgs: e
      }
    },
    verifPermissao() {
      if (this.user.id === 12 || this.user.id === 1) {
        this.titleNew = 'Novo'
      }
      if (this.user.id === 1) {
        this.columns.push({
          field: 'ativo',
          title: 'Ações',
          width: 105,
          template: this.commandTemplate
        })
      }
    },
    commandTemplate(e) {
      var vm = this
      return {
        template: Vue.component('temp', {
          template: `<div class='text-center'>
              <a :href='url(templateArgs.id)' class='btn btn-warning btn-sm btn-editar' title='Editar'
                @click.prevent='templateArgs.status_id == 3 ? null : buttonClick(templateArgs.id)' :disabled='templateArgs.status_id == 3'>
                <i class='fa fa-pencil-square-o'></i> Editar
              </a>
             </div>`,
          data() {
            return {
              templateArgs: {}
            }
          },
          methods: {
            url(id) {
              return `${vm.url}${id}`
            },
            buttonClick(id) {
              vm.$router.push(`${vm.url}${id}`)
            }
          }
        }),
        templateArgs: e
      }
    }
  },
  created() {
    this.verifPermissao()
  }
}
</script>

<style lang="stylus">
.concluido
  text-decoration line-through !important
</style>
