export default [{
	titulo: 'Dúvidas',
	icone: 'menu-icon fa fa-caret-right',
	url: '/sistema/duvidas',
	visualizar: false,
	actions: [{
		value: false,
		name: 'Cadastrar'
	}, {
		value: false,
		name: 'Editar'
	}, {
		value: false,
		name: 'Apagar'
	}]
}]