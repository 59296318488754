<template>
  <div>
    <div class="sw-update-popup" v-show="update">
      <span>Nova versão disponível para atualização!</span>
      <br />
      <button class="btn btn-primary btn-xs" @click.prevent="Atualizar">
        Atualizar
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UpdatePopup',
  watch: {
    $route() {
      this.update = JSON.parse(localStorage.getItem('_update'))
    }
  },
  data() {
    return {
      update: false
    }
  },
  methods: {
    Atualizar() {
      localStorage.setItem('_update', false)
      this.update = false
      window.location.reload()
    }
  }
}
</script>

<style lang="stylus" scoped>
.sw-update-popup {
  position: fixed;
  right: 1em;
  bottom: 1em;
  padding: 1em;
  border: 1px solid #438EB9;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.5);
  text-align: center;
  span {
    margin-bottom: 10px;
    display: block;
  }
}
</style>
