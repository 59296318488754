<template lang="pug">
div
  vue-element-loading(:active='isActive', spinner='ring', color='#428BCA')
  div(v-if='layout')
    label.col-sm-3.control-label.no-padding-right(:for='name')  {{ name }}
    .col-sm-5
        select.form-control(ref="Select", :value='value', @input='updateSelect', :required='required', :disabled='disabled')
            option(:value='null') {{ optionText ? optionText : 'Todos'}}
            option(:value='d[id]', v-for='(d, k) in dados', :key='k', :selected='d[id] == value') {{ d[titulo] }}
  div(v-else)
    label(:for='name') {{ name }}
    select.form-control(ref="Select", :value='value', @input='updateSelect', :required='required', :disabled='disabled')
        option(:value='null') {{ optionText ? optionText : 'Todos'}}
        option(:value='d[id]', v-for='(d, k) in dados', :key='k', :selected='d[id] == value') {{ d[titulo] }}
</template>

<script>
import VueElementLoading from 'vue-element-loading'
import req from '@/components/request'

export default {
  components: {
    VueElementLoading
  },
  props: [
    'value',
    'name',
    'url',
    'params',
    'id',
    'titulo',
    'layout',
    'optionText',
    'required',
    'disabled'
  ],
  data() {
    return {
      dados: [],
      isActive: false
    }
  },

  methods: {
    updateSelect() {
      this.$emit('input', this.$refs.Select.value)
    },
    getRequest() {
      if (sessionStorage.getItem(`_${this.url}`)) {
        this.dados = JSON.parse(sessionStorage.getItem(`_${this.url}`))
      } else {
        this.isActive = true
        req(this.url, 'get', this.params).then(resp => {
          this.dados = resp
          sessionStorage.setItem(`_${this.url}`, JSON.stringify(resp))
          this.isActive = false
        })
      }
    }
  },
  mounted() {
    this.getRequest()
  }
}
</script>

<style lang="stylus" scoped></style>
