<template lang="pug">
div
  page-header(:title='title', :url='url', :titleNew='titleNew')
  grid(:url='urlDataSource', :columns='columns', :source='source')
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import PageHeader from '@/components/PageHeader'
import Grid from '@/components/Grid'

import GridTemplate from '@/mixins/GridTemplate'
moment.locale('pt-BR')

export default {
  mixins: [GridTemplate],
  components: {
    PageHeader,
    Grid
  },
  computed: {
    ...mapGetters(['user'])
  },
  data() {
    return {
      title: 'Atualizações',
      titleNew: '',
      url: '/inicio/atualizacoes/',
      urlDataSource: `${this.$url}/atualizacoes`,
      columns: [
        {
          field: 'texto',
          title: 'Título',
          width: 400
        },
        {
          field: 'datacad',
          title: 'Data',
          width: 120
        }
      ],
      source: {
        schemaParse: this.parse
      }
    }
  },
  methods: {
    verifPermissao() {
      this.titleNew = 'Novo'
      this.columns.push({
        field: 'datacad',
        title: 'Ações',
        width: 105,
        template: this.commandTemplate
      })
    },
    parse(d) {
      d.map(i => {
        i.datacad = moment(i.datacad).format('L LT')
        return i
      })
      return d
    }
  },
  created() {
    if (this.user.id === 1) {
      this.verifPermissao()
    }
  }
}
</script>

<style lang="stylus" scoped></style>
