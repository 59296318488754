<template>
    <div ref="tb"
        class="tool-bar flex justify-content-start mb-6 text-gray-400 space-x-3 bg-gray-200 my-2 ml-2 bg-gray-100 w-max		p-2 rounded-lg ">
        <!-- <i @click="uploadAll()" title="Start Upload"
            class="transition-colors	duration-700 cursor-pointer  hover:text-green-500 fa fa-arrow-circle-up"></i>
        <i @click="cancelUploadAll()" title="Cancel Upload"
            class=" transition-colors	duration-700 cursor-pointer hover:text-red-400  fa fa-ban"></i> -->
        <i @click="deleteAll()" title="Apagar"
            class=" transition-colors	duration-700 cursor-pointer hover:text-red-400  fa fa-trash-o"></i>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'toolbar',
    methods: {
        uploadAll(form) {
            const keys = Object.keys(form)
            if (this.$parent.$data.uploading || this.$parent.$data.files.length == 0 || this.$parent.$data.files.every(thing => thing === null)) return

            this.$parent.$data.uploading = true
            return new Promise((resolve) => {
                this.$parent.$data.files.forEach(async (cur, index) => {
                    this.$parent.$data.request = null
                    this.$parent.$data.request = new FormData()
                    if (cur == null) return
                    this.$parent.$data.request.append('arquivos', this.$parent.$data.files[index])
                    keys.forEach(ap => {
                        this.$parent.$data.request.append(ap, form[ap])
                    })
                    //this.$parent.$data.request.append('form', form)
                    this.$parent.$data.temporaryFiles[index].status = 'uploading'
                    this.$parent.$data.temporaryFiles[index].source = this.$parent.createCancelToken()

                    const config = {
                        headers: { 'Content-Type': 'multipart/form-data' },
                        cancelToken: this.$parent.$data.temporaryFiles[index].source.token,
                        onUploadProgress: progressEvent => {
                            const progress = (progressEvent.loaded / progressEvent.total) * 100
                            this.$parent.$data.temporaryFiles[index].percent = progress
                            if (progress === 100) {
                                return
                            }
                        }
                    }

                    await axios.post(this.$parent.$props.url, this.$parent.$data.request, config).catch(function (error) {

                        error

                    })

                    // handle success
                    this.$parent.$data.temporaryFiles[index].status = 'uploaded'
                    this.$parent.$data.files[index] = null
                    if (this.$parent.$data.files.length - 1 == index) this.$parent.$data.uploading = false
                    if (!this.$parent.isAllUploading()) this.$parent.$data.uploading = false


                    this.$parent.$data.request.delete('file')
                    if(this.$parent.$data.files.length - 1 === index)
                        resolve()
                })
            })
        },
        deleteAll() {

            if (this.$parent.$data.uploading) this.cancelUploadAll()

            this.$parent.$data.temporaryFiles.forEach(cur => {
                URL.revokeObjectURL(cur)
            })
            this.$parent.$data.temporaryFiles = []
            this.$parent.$data.files = []
            this.$parent.$data.request.delete('file')

        },
        cancelUploadAll() {

            this.$parent.$data.uploading = false

            this.$parent.$data.temporaryFiles.forEach(cur => {
                cur.source.cancel()
                cur.status = 'cancel'
            })

        },

    }
}
</script>