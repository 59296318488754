<template lang="pug">
div
  vue-element-loading(:active='isActive', spinner='bar-fade-scale', color='#428BCA')
  .alert.alert-success.alert-dismissible.fade.in(role='alert')
    | Bem-vindo ao &nbsp;
    strong
      i.fa.fa-wifi
      | admiN
    | , seu nome é {{ user.name }} o seu IP é {{ ip }}, utilize o menu esquerdo pra visualizar cada opção.
</template>

<script>
import jwt from 'jwt-simple'
import VueElementLoading from 'vue-element-loading'
import req from '@/components/request'

export default {
  components: {
    VueElementLoading
  },
  data() {
    return {
      user: {},
      ip: '',
      isActive: false
    }
  },
  methods: {
    getIp() {
      req('/ip')
        .then(resp => {
          this.ip = resp.ip
          this.isActive = false
        })
        .catch(response => {
          response
        })
    }
  },
  created() {
    this.isActive = true
    let user = localStorage.getItem('user')
    if (user) {
      this.user = JSON.parse(jwt.decode(user, 'admin'))
      this.getIp()
    }
  }
}
</script>

<style lang="stylus" scoped></style>
