import axios from 'axios'
import jwt from 'jwt-simple'

export const getMenu = ({ commit }) => {
  const dados = JSON.parse(jwt.decode(localStorage.getItem('user'), 'admin'))
  axios
    .get('/menu', {
      params: { id: dados.id }
    })
    .then(item => {
      localStorage.setItem('_menu', item.data[0].menu)
      commit('setMenu', JSON.parse(item.data[0].menu))
    })
}

export const getUser = ({ commit }, user) => {
  commit('setUser', user)
}

export const getLogsTotal = ({ commit }, id) => {
  axios.get(`/logs/count/${id}`).then(resp => {
    axios.get(`/logs/last/${id}`).then(dados => {
      commit('setLogs', dados.data)
    })
    commit('setLogsTotal', resp.data.total)
  })
}
