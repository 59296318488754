<template lang="pug">
div
  page-header(:title='title', :url='url', :titleNew='titleNew')
  .row
    .col-md-3
      label Usuário:
      input.form-control(v-model="filtro.usuario", placeholder="Usuário")
    .col-md-2
      label E-mail:
      input.form-control(v-model="filtro.email", placeholder="E-mail")
    .col-md-2
      label CPF:
      input.form-control(v-model="filtro.cpf", placeholder="CPF")
    .col-md-3
      select-form(v-model='filtro.perfil', :value='filtro.perfil', name='Grupo de Usuários', url='/perfil', id='id', titulo='titulo')
    .col-md-2
      select-form(v-model='filtro.bloquear', :value='filtro.bloquear', name='Ativo', url='/arquivos', id='id', titulo='nome')
  br
  grid(:url='urlDataSource', :columns='columns', :grid='{height:500}', :source='source')
</template>

<script>
import PageHeader from '@/components/PageHeader'
import Grid from '@/components/Grid'
import SelectForm from '@/components/form/SelectForm'
import GridTemplate from '@/mixins/GridTemplate'
import Permission from '@/mixins/Permission'

export default {
  mixins: [GridTemplate, Permission],
  components: {
    PageHeader,
    Grid,
    SelectForm
  },
  computed: {
    filter() {
      return {
        logic: 'and',
        filters: [
          {
            field: 'name',
            operator: 'contains',
            value: this.filtro.usuario
          },
          {
            field: 'email',
            operator: 'contains',
            value: this.filtro.email
          },
          {
            field: 'cpf',
            operator: 'contains',
            value: this.filtro.cpf
          },
          {
            field: 'perfil',
            operator: 'contains',
            value: this.filtro.perfil
          },
          {
            field: 'bloquear',
            operator: 'contains',
            value: this.filtro.bloquear
          }
        ]
      }
    },
    source() {
      return [
        {
          filter: this.filter
        },
        {
          serverFiltering: true
        }
      ]
    }
  },
  data() {
    return {
      title: 'Usuários',
      titleNew: '',
      url: '/admin/usuarios/',
      urlDataSource: `${this.$url}/usuarios/lista`,
      filtro: [
        {
          usuario: '',
          email: '',
          cpf: '',
          perfil: '',
          bloquear: ''
        }
      ],
      columns: [
        {
          field: 'cpf',
          title: 'CPF',
          width: 120
        },
        {
          field: 'name',
          title: 'Usuário',
          width: 300
        },
        {
          field: 'email',
          title: 'E-mail',
          width: 300
        },
        {
          field: 'perfil',
          title: 'Grupo de Acesso',
          width: 100
        },
        {
          field: 'nome',
          title: 'Ativo',
          width: 120
        }
      ]
    }
  },
  methods: {
    verifPermissao() {
      this.titleNew = this.ACTION.c ? 'Novo' : ''
      if (this.ACTION.e) {
        this.columns.push({
          field: 'ativo',
          title: 'Ações',
          width: 105,
          template: this.commandTemplate
        })
      }
    }
  },
  created() {
    this.verifPermissao()
  }
}
</script>

<style lang="stylus" scoped></style>
