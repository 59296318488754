<template>
  <div>
    <page-header :title="title"></page-header>
    <form @submit.prevent="gravar">
      <div class="modal-body">
        <div class="form-horizontal">
          <vue-element-loading
            :active="isActive"
            spinner="bar-fade-scale"
            color="#428BCA"
          ></vue-element-loading>
          <input-form-text
            textInput="Título"
            :required="true"
            v-model="form.title"
            :value="form.title"
          ></input-form-text>
          <div class="form-group">
            <label class="col-sm-3 control-label no-padding-right">Valor</label>
            <div class="col-sm-9">
              <money
                class="col-xs-10 col-sm-3 moeda"
                type="text"
                name="valor"
                v-model="form.value"
                placeholder="Valor"
                required=""
                v-bind="money"
              ></money>
            </div>
          </div>
          <input-form-text
            textInput="Benefícios"
            :required="true"
            v-model="form.beneficios"
            :value="form.beneficios"
          ></input-form-text>
          <div class="form-group">
            <select-form
              v-model="form.destaque"
              :value="form.destaque"
              name="Destaque"
              url="/arquivos?cat=1"
              id="id"
              titulo="nome"
              layout="true"
              optionText="Selecione"
              required="true"
            ></select-form>
          </div>
          <div class="form-group">
            <select-form
              v-model="form.ativo"
              :value="form.ativo"
              name="Ativo"
              url="/arquivos?cat=1"
              id="id"
              titulo="nome"
              layout="true"
              optionText="Selecione"
              required="true"
            ></select-form>
          </div>
        </div>
      </div>
      <footer-button :disabled="isActive"></footer-button>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VueElementLoading from 'vue-element-loading'
import PageHeader from '@/components/PageHeader'
import InputFormText from '@/components/form/InputForm'
import FooterButton from '@/components/form/FooterButton'
import Permission from '@/mixins/Permission'
import Services from '@/utils/services'
import { sucesso, erro } from '@/components/Message'
import { Money } from 'v-money'
import SelectForm from '@/components/form/SelectForm'

export default {
  components: {
    PageHeader,
    InputFormText,
    FooterButton,
    VueElementLoading,
    Money,
    SelectForm
  },
  mixins: [Permission],
  computed: {
    ...mapGetters(['user'])
  },
  data() {
    return {
      title: 'Planos',
      form: {
        title: ''
      },
      isActive: false,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false
      }
    }
  },
  methods: {
    ...mapActions(['getLogsTotal']),
    async gravar() {
      this.isActive = true
      const body = this.form
      body.title = body.title.trim()
      if (this.$route.params.id) {
        await Services.put(`/planos/${this.$route.params.id}`, body)
          .then(() => {
            sucesso('Atualizado...')
            this.$router.push('/sistema/planos')
          })
          .catch(response => {
            response
            erro()
          })
      } else {
        await Services.post('/planos', body)
          .then(() => {
            sucesso('Atualizado...')
            this.$router.push('/sistema/planos')
          })
          .catch(response => {
            response
            erro()
          })
      }
      this.isActive = false
    },
    async getDados() {
      const dados = await Services.get(
        `/planos/${this.$route.params.id}`
      ).catch(response => {
        response
        return (this.isActive = false)
      })
      this.form.title = dados.title
      this.form.value = dados.value ? Number(dados.value) : 0
      this.form.beneficios = dados.beneficios
      this.form.destaque = dados.destaque
      this.form.ativo = dados.ativo
      this.isActive = false
    },
    verifPermissao() {
      if (this.$route.params.id) {
        if (!this.ACTION.e) {
          this.$router.push('/erro')
        }
      } else {
        if (!this.ACTION.c) {
          this.$router.push('/erro')
        }
      }
    }
  },
  mounted() {
    this.verifPermissao()
    if (this.$route.params.id) {
      this.isActive = true
      this.getDados()
    }
  }
}
</script>

<style lang="stylus" scoped></style>
