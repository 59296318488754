<template lang="pug">
div
  br
  br
  .col-md-12.text-center
    h2 CONTRATO
    br
    br
  vue-element-loading(:active='isActive', spinner='bar-fade-scale', color='#428BCA')
  div
    .col-md-6 Contratante:
    .col-md-6
      | Data da Venda
      strong  {{venda.datacad | dataHora}}
    br
    br
    div(style='clear: both')
    p
      | Nome:
      strong  {{clientes.nome}}
    p
      | Tel:
      strong  {{clientes.telefone1}}
    p
      | End:
      strong  {{clientes.end_rua}}
      |  n°
      strong  {{clientes.end_numero}}
    p
      | Comp.
      strong  {{clientes.end_compl}}
      |  Bairro:
      strong  {{clientes.end_bairro}}
    p
      | CEP:
      strong  {{clientes.end_cep}}
      |  Cidade/UF:
      strong  {{clientes.end_cidade}}/{{clientes.end_estado}}
    p
      | CPF/Cnpj:
      strong  {{clientes.cpf_cnpj}}
    p
      | Data de Instalação:
      strong  {{venda.dt_instalacao}}
    p
      | Status:
      strong  {{venda.status_nome}}
    p
      | Taxa de Adesão:
      strong  {{venda.tx}}
    br
    br
    h4.text-center Descrição dos Serviços
    table.table.table-borderd.table-striped
      tbody
        tr
          td Descrição
          td Qtd
          td Obs
          td Unitário
          td Total
        tr(v-for='v in vendaprod')
          td {{v.produto_nome}}
          td {{v.produto_qtd}}
          td {{v.produto_obs}}
          td {{v.produto_preco}}
          td.text-right {{v.produto_subtotal}}
        tr
          td(colspan='2') Previsão de Instalação: {{venda.previsao_inst | dataCad}}
          td.text-right(colspan='3') Total: R$ {{venda.total}}
    br
    table.table.table-borderd.table-striped
      tbody
        tr
          td
            p Observações:
            | 				{{venda.obs}}
    p.small
      | Autorizo a fabricarem e concordo com o Sistema de Pagamento à vista, parcelado em cheques ou cartão de credito, pagarei 20% do valor do contrato no caso de desistência e vencidas três parcelas (cheques) as demais vencerão antecipadamente, tudo relacionado aos itens descritos acima.
    br
    br
    br
    .col-md-6.text-center
      p ___________________________________
      p Cliente
    .col-md-6.text-center
      p ___________________________________
      p LigNet Telecom
    br
    br
    .text-center.no-print
      a.btn.btn-success(@click.prevent='getImprimir', href='#')
        i.fa.fa-print(aria-hidden='true')
        |  Imprimir

</template>

<script>
import moment from 'moment'
import req from '@/components/request'
import VueElementLoading from 'vue-element-loading'
import { erro } from '@/components/Message'
moment.locale('pt-BR')

export default {
  components: {
    VueElementLoading
  },
  filters: {
    dataHora(x) {
      return moment(x).format('L LT')
    },
    dataCad(x) {
      return moment(x).format('L')
    }
  },
  data() {
    return {
      vlrvenda: 0,
      venda: [],
      clientes: [],
      enderecos: [],
      vendaprod: [],
      isActive: false
    }
  },
  methods: {
    getRecibo() {
      req(`/vendas/${this.$route.params.id}`)
        .then(resp => {
          this.venda = resp
          this.getCliente(resp.clientes_id)
          this.getVendasProdutos()
        })
        .catch(response => {
          response
          erro()
        })
    },
    getVendasProdutos() {
      req(`/vendas/produtos/${this.$route.params.id}`)
        .then(resp => {
          this.vendaprod = resp
        })
        .catch(response => {
          response
          erro()
        })
    },
    getCliente(id) {
      req(`/clientes/${id}`)
        .then(resp => {
          delete resp.solicitante
          delete resp.usuario_nome
          delete resp.usuario_cad_id
          delete resp.usuario_cad_nome
          delete resp.datacad
          delete resp.datacad_atu
          delete resp.mae
          delete resp.mae_fantasia
          delete resp.id
          delete resp.usuario_id
          this.clientes = { ...this.clientes, ...resp }
          this.getEndereco(id)
        })
        .catch(response => {
          response
          erro()
        })
    },
    getEndereco(id) {
      req(`/clientes/${id}/endereco`)
        .then(resp => {
          delete resp.principal
          delete resp.cliente_id
          delete resp.usuario_id
          delete resp.id
          this.clientes = { ...this.clientes, ...resp }
          this.isActive = false
        })
        .catch(response => {
          response
          erro()
        })
    },
    getImprimir() {
      window.print()
    }
  },
  mounted() {
    this.isActive = true
    this.getRecibo()
  }
}
</script>

<style lang="stylus" scoped></style>
