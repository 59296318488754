import Vue from 'vue'

export default (e, color, title) => {
  return {
    template: Vue.component('temp', {
      template: `
          <div style='height: 57px;'>
            <span
              style='display: block;padding: 5px'
              :style='[
                { "background-color" : backgroundColor },
                {color : color}]'>
                {{ templateArgs[title] }}
              </span>
          </div>`,
      data() {
        return {
          templateArgs: {},
          backgroundColor: '',
          color: '',
          title: ''
        }
      },
      methods: {
        hexToRgb(hex) {
          let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
          return result
            ? {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16)
              }
            : null
        },
        getColorByBgColor(hexcolor) {
          let r = this.hexToRgb(hexcolor).r
          let g = this.hexToRgb(hexcolor).g
          let b = this.hexToRgb(hexcolor).b
          let yiq = (r * 299 + g * 587 + b * 114) / 1000
          return yiq >= 128 ? 'black' : 'white'
        }
      },
      mounted() {
        this.backgroundColor = this.templateArgs[color]
        this.color = this.getColorByBgColor(this.templateArgs[color])
        this.title = title
      }
    }),
    templateArgs: e
  }
}
